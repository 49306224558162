<template>
  <y-layout-body-menu aside-width="8rem" :menu="[
    {title:'基础配置',path:'/card/plugin/principal/rule'},
    {title:'主理人管理',path:'/card/plugin/principal/user'},
    {title:'主理人订单',path:'/card/plugin/principal/order'},
  ]">
    <router-view/>
  </y-layout-body-menu>
</template>

<script>
import YLayoutBodyMenu from "@/components/layouts/YLayoutBodyMenu";

export default {
  name: "index",
  components: {YLayoutBodyMenu},
}
</script>

<style scoped>

</style>